import { inject, Injectable } from '@angular/core'
import { UserService } from './user.service'
import { RouteHeaderUrl } from '../../shared/components/one-header/header-navigation.model'
import moment from 'moment'
import { StripeSubscription } from '../../pages/cart/models/cart-models'
import { ProductPageConfig } from '../../pages/cart/services/products-page-config'
import { BehaviorSubject, catchError, finalize, forkJoin, map, Observable, of, Subscription, switchMap, timeout } from 'rxjs'
import { CartService } from '../../pages/cart/services/cart.service'
import { RewardsType } from '../../pages/coupon-codes/models/rewards-model'
import { ApiRewardsService } from './api/api-rewards.service'
import { DashboardAltApiService } from '../../pages/dashboard-alt/services/dashboard-alt-api.service'
import { StoreState } from '../../store/store.state'
import { Store } from '@ngrx/store'
import { HideLoading, ShowLoading } from '../../store/loading/loading.actions'
import { CouponStatus } from '../../pages/coupon-codes/models/coupon-code'
import { ApiService } from './api/api.service'

export interface FeatureStatus {
  limit: number | null
  used: number | null
  blocked: boolean
  upgradeUrl: string
}

@Injectable({providedIn: 'root'})

export class StripeLimitEnforcementService {
  public stripeSubscription: StripeSubscription = this.userService.stripeSubscription
  private productPageConfig = new ProductPageConfig().config
  private subscription = new Subscription()
  private featureConfigInitialized = false
  private apiRewardsService: ApiRewardsService = inject(ApiRewardsService)
  private dashboardAltApiService: DashboardAltApiService = inject(DashboardAltApiService)

  public period = {
    start: moment.utc(this.stripeSubscription.current_period_start * 1000).format('YYYY-MM-DD'),
    end: moment.utc(this.stripeSubscription.current_period_end * 1000).format('YYYY-MM-DD'),
  }

  private featureConfig = {
    [RouteHeaderUrl.emails]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.emails),
    },
    [RouteHeaderUrl.subscribe_forms]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.subscribe_forms),
    },
    [RouteHeaderUrl.announcements]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.announcements),
    },
    [RouteHeaderUrl.social_proof]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.social_proof),
    },
    [RouteHeaderUrl.upsell]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.upsell),
    },
    // [RouteHeaderUrl.coupons]: {
    //   limit: null,
    //   used: null,
    //   blocked: false,
    //   upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.coupons),
    // },
    // [RouteHeaderUrl.gift_cards]: {
    //   limit: null,
    //   used: null,
    //   blocked: false,
    //   upgradeUrl: this.getUpgradeUrl(RouteHeaderUrl.gift_cards),
    // },
    [RouteHeaderUrl.sms]: {
      limit: null,
      used: null,
      blocked: false,
      upgradeUrl: `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.sms}`,
    },
  }

  public feature$ = new BehaviorSubject<{[key: string]: FeatureStatus}>(null)

  constructor(
    private userService: UserService,
    private cartService: CartService,
    private apiService: ApiService,
    private store: Store<StoreState>,
  ) {
    this.subscription.add(
      this.cartService.updatePurchasedProducts().pipe(
        map(() => {
          // create config and set limits from productsPageConfig
          const configWithLimits = this.updateLimits(structuredClone(this.featureConfig))
          return configWithLimits
        }),
        switchMap((configWithLimits) => {
          return forkJoin({
            [RouteHeaderUrl.emails]: this.getEmailsSent(),
            // [RouteHeaderUrl.coupons]: this.getRewards(RewardsType.coupon_code),
            // [RouteHeaderUrl.gift_cards]: this.getRewards(RewardsType.gift_card),
            // This one is subscribe_forms combined with upsell limit because it's the same request
            mixed: this.getTotalUniques(),
          }).pipe(
            map((results) => {
              results[RouteHeaderUrl.subscribe_forms] = results.mixed.subscribeForm
              results[RouteHeaderUrl.upsell] = results.mixed.upsell
              Object.keys(configWithLimits).forEach(feature => {
                if (feature === RouteHeaderUrl.sms) {
                  // Special treatment for SMS credits since they are coming from a /me endpoint
                  const smsCreditsUsed = this.userService.userInfo?.sms_marketing_account?.credits_used || 0
                  configWithLimits[feature].used = this.getNumber(smsCreditsUsed)
                } else {
                  configWithLimits[feature].used = this.getNumber(results[feature])
                }
                configWithLimits[feature].blocked = configWithLimits[feature].used >= configWithLimits[feature].limit
              })
              return configWithLimits
            })
          )
        })
      ).subscribe({
        next: (results) => {
          this.feature$.next(results)
        }
      })
    )
  }

  public updateStatus(feature: RouteHeaderUrl, limit: number, used: number) {
    if (!this.featureConfigInitialized) {
      console.error('StripeLimitEnforcementService.updateStatus(): Feature config not initialized yet')
      return
    }
    if (!this.feature$.value[feature]) {
      return
    }
    if (limit === undefined || used === undefined) {
      return
    }
    if (limit === 0) {
      this.feature$.next({
        ...this.feature$.value,
        [feature]: {
          ...this.feature$.value[feature],
          limit: 0,
          used: used,
          blocked: false,
        }
      })
      return
    }
    this.feature$.next({
      ...this.feature$.value,
      [feature]: {
        ...this.feature$.value[feature],
        limit: limit,
        used: used,
        blocked: used >= limit,
      }
    })
  }

  public getStatus(feature: RouteHeaderUrl) {
    if (!this.featureConfigInitialized) {
      console.error('StripeLimitEnforcementService.getStatus(): Feature config not initialized yet')
      return null
    }
    return this.feature$.value[feature]
  }

  private getUpgradeUrl(feature: RouteHeaderUrl): string {
    const item = this.productPageConfig.flatMap(product => product.sections).find(section => section.id === feature)
    const baseUrl = `/${RouteHeaderUrl.settings}/${RouteHeaderUrl.billing}/${RouteHeaderUrl.subscriptions}/${RouteHeaderUrl.custom}`
    if (item.url) {
      return `${baseUrl}/${item.url}`
    } else {
      return ''
    }
  }

  private updateLimits(features: {[key: string]: FeatureStatus}): {[key: string]: FeatureStatus} {
    const pageConfig = this.cartService.productsPageConfig$?.value
    const sections = pageConfig?.flatMap(c => c?.sections)
    Object.keys(features).forEach(feature => {
      const section = sections.find(section => section.id === feature)
      if (section) {
        const value = section.purchasedProduct?.quantity || section.purchasedProduct?.metadata?.quantity || section.selectedProduct?.metadata?.quantity
        features[feature].limit = this.getNumber(value)
        // Special treatment for SMS credits
        const smsCreditsPurchased = this.userService.smsCreditsPurchased || 0
        if (feature === RouteHeaderUrl.sms) {
          features[feature].limit = smsCreditsPurchased
        }
      }
    })
    return features
  }


  private getRewards(rewardType: RewardsType): Observable<number> {
    this.store.dispatch(new ShowLoading('RewardsLimits'))
    return this.apiRewardsService.getRewardStats({
      includes: 'stats',
      status: CouponStatus.allExceptArchived,
      start: this.period.start,
      end: this.period.end,
    }, rewardType).pipe(
      map(res => this.getNumber(res?.stats?.num_awarded)),
      catchError(err => {
        console.error('Unable fetch Rewards', err)
        return of(0)
      }),
      finalize(() => this.store.dispatch(new HideLoading('RewardsLimits'))),
    )
  }

  private getEmailsSent(): Observable<number> {
    this.store.dispatch(new ShowLoading('EmailsLimits'))
    return this.apiService.get(`/v1/me/stats/messaging/email?start_date=${this.period.start}&end_date=${this.period.end}`).pipe(
      map(res => this.getNumber(res?.stats?.num_sent)),
      catchError(err => {
        console.error('Unable fetch Emails', err)
        return of(0)
      }),
      finalize(() => this.store.dispatch(new HideLoading('EmailsLimits'))),
    )
  }

  private getTotalUniques(): Observable<{subscribeForm: number, upsell: number}> {
    this.store.dispatch(new ShowLoading('PopUpsLimits'))
    const fetchTimeout = 45000
    return this.dashboardAltApiService.getTotalUnique(
      this.period.start,
      this.period.end,
    ).pipe(
      timeout(fetchTimeout),
      map(res => ({ subscribeForm: this.getNumber(res?.total_uniq), upsell: this.getNumber(res?.total_orders) })),
      catchError(err => {
        console.error('Unable fetch Analytics', err)
        return of({subscribeForm: 0, upsell: 0})
      }),
      finalize(() => this.store.dispatch(new HideLoading('PopUpsLimits'))),
    )
  }

  private getNumber(value: any): number {
    return typeof value === 'number' ? value : Number(value) || 0
  }

}
