<div class="limits">
  @for (item of displayLimitsConfig; track item.id) {
    @defer {
      <div class="limits-item" 
        [oneTooltip]="{content: limitBasedTooltip, context: {item: item}}"
        [oneTooltipSkipUnderline]="true"
        [oneTooltipWidth]="350"
        oneTooltipPosition="bottom"
        [oneTooltipInteractive]="true">
        <div class="limits-item-title">
          <one-icon [name]="item.icon"></one-icon>
          <span [routerLink]="[item.redirectUrl]"> {{ item.title }}</span>
        </div>
        @if (item.type === LimitType.limitBased) {
          <div class="limits-item-progress"
            [class._empty]="item.value?.percent === 0">
            <div class="progress-bar"
              [class._danger]="item.value?.percent < 25"
              [class._warning]="item.value?.percent < 50"
              [style.width.%]="item.value?.percent"
            ></div>
          </div>
        }
        @if (item.type === LimitType.creditBased) {
          <div class="limits-item-value">
            {{ item.value?.credits | number: '1.0-0' }}
          </div>
        }
      </div>
    }
    @placeholder {
      <div class="limits-item">
        <div class="limits-item-title">
          <one-icon [name]="item.icon"></one-icon>
          {{ item.title }}
        </div>
        <div class="limits-item-progress">
          <div class="progress-bar _loading">
            <div class="animated-rectangle"></div>
          </div>
        </div>
      </div>
    }
  }
</div>

<ng-template #limitBasedTooltip let-item="item">
  <div class="limit-tooltip">
    <div class="limit-tooltip-title">
      <one-icon 
        class="mr-1"
        [name]="item.icon"
      ></one-icon> 
      {{ item.title }}
    </div>
    @if (item.groupWith) {
      <div class="limit-tooltip-record">
        <div>
          {{ item.titleWhenGrouped }}
        </div>
        <div class="_spacer"></div>
        <div>
          <span [class.text-danger]="item.value?.used > item.value?.limit"
         >{{ item.value?.used | number: '1.0-0' }}</span> / <strong>{{ item.value?.limit | number: '1.0-0' }}</strong>
        </div>
      </div>
      @for (groupItem of item.groupWith; track groupItem.title) {
        <div class="limit-tooltip-record">
          <div>
            {{ groupItem.title }}
          </div>
          <div class="_spacer"></div>
          <div>
            <span [class.text-danger]="item.value?.used > groupItem.value?.limit"
           >{{ item.value?.used | number: '1.0-0' }}</span> / <strong>{{ groupItem.value?.limit | number: '1.0-0' }}</strong>
          </div>
        </div>
      }
    }
    @if (!item.groupWith && item.type !== LimitType.creditBased) {
      <div class="limit-tooltip-record">
        <div>
          Used  
        </div>
        <div class="_spacer"></div>
        <div>
          <span [class.text-danger]="item.value?.used > item.value?.limit"
         >{{ item.value?.used | number: '1.0-0' }}</span> / <strong>{{ item.value?.limit | number: '1.0-0' }}</strong>
        </div>
      </div>
    }
    <div>
      <a class="pf-button outline black xs mt-2 w-100" 
        (click)="upgrade(item.upgradeUrl)"
      >{{ item.buttonText }}</a>
    </div>
  </div>
</ng-template>
