import { CommonModule } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { filter, Subscription } from 'rxjs'
import { RouteHeaderUrl } from '../../header-navigation.model'
import { EmailsService } from '../../../../../pages/emails/services/emails.service'
import { DashboardAltApiService } from '../../../../../pages/dashboard-alt/services/dashboard-alt-api.service'
import { OneIconComponent } from '../../../one-icon/one-icon.component'
import { OneTooltipDirective } from '../../../../directives/one-tooltip.directive'
import { Router, RouterModule } from '@angular/router'
import { FeatureStatus, StripeLimitEnforcementService } from '../../../../../core/services/stripe-limit-enforcement.service'

export enum LimitType {
  limitBased = 'limitBased',
  creditBased = 'creditBased',
}

export interface LimitItem {
  title: string
  id: RouteHeaderUrl
  icon: string
  value?: {used: number, percent: number, limit: number, credits?: number}
  buttonText: string
  upgradeUrl?: string
  redirectUrl?: string
  groupWith?: any
  titleWhenGrouped?: string
  blocked?: boolean
  type?: LimitType
}

@Component({
  selector: 'one-main-nav-limits',
  standalone: true,
  imports: [
    CommonModule,
    OneIconComponent,
    OneTooltipDirective,
    RouterModule,
  ],
  providers: [
    EmailsService,
    DashboardAltApiService,
  ],
  templateUrl: './one-main-nav-limits.component.html',
  styleUrl: './one-main-nav-limits.component.scss',
})
export class OneMainNavLimitsComponent implements OnInit, OnDestroy {
  subscription: Subscription = new Subscription()
  displayLimitsConfig: LimitItem[] = [
    {
      title: 'Emails',
      id: RouteHeaderUrl.emails,
      icon: 'mail-outline',
      redirectUrl: `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.emails}/${RouteHeaderUrl.campaigns}`,
      type: LimitType.limitBased,
      buttonText: 'Upgrade',
    },
    {
      title: 'Pop Ups',
      id: RouteHeaderUrl.subscribe_forms,
      icon: 'albums-outline',
      redirectUrl: `/${RouteHeaderUrl.popups}/${RouteHeaderUrl.campaigns}`,
      type: LimitType.limitBased,
      buttonText: 'Upgrade',
      titleWhenGrouped: 'Subscribe Forms',
      groupWith: [
        {
          id: RouteHeaderUrl.announcements,
          title: 'Announcements',
          icon: 'albums-outline',
          buttonText: 'Upgrade',
        }, 
        {
          id: RouteHeaderUrl.social_proof,
          title: 'Social Proof',
          icon: 'albums-outline',
          buttonText: 'Upgrade',
        },
      ],
    },
    {
      title: 'Upsell Pages',
      id: RouteHeaderUrl.upsell,
      icon: 'cart-outline',
      redirectUrl: `/${RouteHeaderUrl.upsell}`,
      type: LimitType.limitBased,
      buttonText: 'Upgrade',
    },
    // {
    //   title: 'Coupons',
    //   id: RouteHeaderUrl.coupons,
    //   icon: 'ticket-outline',
    //   redirectUrl: `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.coupons}`,
    //   type: LimitType.limitBased,
    // },
    // {
    //   title: 'Gift Cards',
    //   id: RouteHeaderUrl.gift_cards,
    //   icon: 'card-outline',
    //   redirectUrl: `/${RouteHeaderUrl.rewards}/${RouteHeaderUrl.gift_cards}`,
    //   type: LimitType.limitBased,
    // },
    {
      title: 'SMS Credits',
      id: RouteHeaderUrl.sms,
      icon: 'chatbox-ellipses-outline',
      redirectUrl: `/${RouteHeaderUrl.messaging}/${RouteHeaderUrl.sms}/${RouteHeaderUrl.campaigns}`,
      type: LimitType.creditBased,
      buttonText: 'Buy More Credits',
    }
  ]
  LimitType = LimitType
  
  constructor(
    private router: Router,
    private stripeLimitEnforcementService: StripeLimitEnforcementService,
  ) {
  }

  ngOnInit(): void {
    this.subscription.add(
      this.stripeLimitEnforcementService.feature$.asObservable().pipe(
        filter(feature => feature !== null),
      ).subscribe((feature: {[key: string]: FeatureStatus}) => {
        this.displayLimitsConfig.forEach(item => {
          if (feature.hasOwnProperty(item.id)) {
            item.value = this.getValue(feature[item.id].used, feature[item.id].limit, item.type) 
            item.upgradeUrl = feature[item.id].upgradeUrl
            item.blocked = feature[item.id].blocked
          }
          if (item.hasOwnProperty('groupWith')) {
            item.groupWith?.forEach(groupItem => {
              if (feature.hasOwnProperty(groupItem.id)) {
                groupItem.value = this.getValue(feature[groupItem.id].used, feature[groupItem.id].limit, item.type) 
                groupItem.upgradeUrl = feature[groupItem.id].upgradeUrl
                groupItem.blocked = feature[groupItem.id].blocked
              }
            })
          }
        })
      })
    )
  }

  upgrade(url: string) {
    this.router.navigate([url])
  }

  getValue(used: number, limit: number, type: LimitType): {used: number, percent: number, limit: number, credits?: number} {
    used = Number(used)
    limit = Number(limit)
    if (isNaN(used) || isNaN(limit)) {
      return {
        percent: 0,
        used: 0,
        limit: limit,
      }
    }
    const percent = 100 - (used / limit * 100)
    const creditBasedLimit = (limit - used > 0) ? limit - used : 0
    if (type === LimitType.limitBased) {
      return {
        percent: percent > 0 ? percent : 0,
        used: used,
        limit: limit,
      }
    } else if (type === LimitType.creditBased) {
      return {
        percent: 0,
        used: used,
        limit: limit,
        credits: creditBasedLimit,
      }
    }
  }
  
  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }
}
